import React from "react"
import PropTypes from "prop-types"
import { CartesianGrid, Line, Area, AreaChart, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import moment from "moment"

const FlavorOppsChart = (props) => {
	return (
		<div style={{ width: "100%", height: 250 }}>
			<ResponsiveContainer>
				<LineChart data={props.data} margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dataKey="date"
						tickFormatter={(item) => (props.rangeSpan === "day" ? moment(item).format("MMM D") : item)}
					/>
					<YAxis allowDecimals={false} />
					<Tooltip />
					<Line dot={false} strokeWidth={2} dataKey="opportunities" stroke="#3F8DA9" name="Opportunities" />
					<Line dot={false} strokeWidth={2} dataKey="flavorings" stroke="#F5C05B" name="Flavorings" />
					<Line dot={false} activeDot={false} strokeWidth={0} dataKey="stores" stroke="#74A356" name="Stores" />
					<Line
						dot={false}
						activeDot={false}
						strokeWidth={0}
						dataKey="oppsPerStore"
						stroke="#7B4EA7"
						name="Opps/Store"
					/>
					<Line
						dot={false}
						activeDot={false}
						strokeWidth={0}
						dataKey="flavoringsPerStore"
						stroke="#A9373B"
						name="Flavorings/Store"
					/>
				</LineChart>
			</ResponsiveContainer>
		</div>
	)
}
FlavorOppsChart.propTypes = {
	data: PropTypes.array,
	rangeSpan: PropTypes.any,
}

const AvgFlavorChart = (props) => {
	return (
		<div style={{ width: "100%", height: 250 }}>
			<ResponsiveContainer>
				<AreaChart data={props.data} margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dataKey="date"
						tickFormatter={(item) => (props.rangeSpan === "day" ? moment(item).format("MMM D") : item)}
					/>
					<YAxis allowDecimals={false} />
					<Tooltip />
					<Area dataKey="performance" stroke="#F5C05B" fillOpacity={0.2} fill="#F5C05B" />
				</AreaChart>
			</ResponsiveContainer>
		</div>
	)
}
AvgFlavorChart.propTypes = {
	data: PropTypes.array,
	rangeSpan: PropTypes.any,
}

const propTypes = {
	data: PropTypes.array,
	rangeSpan: PropTypes.string,
}

AvgFlavorChart.propTypes = propTypes
FlavorOppsChart.propTypes = propTypes

export { AvgFlavorChart, FlavorOppsChart }
